import React from 'react'

import { logger } from '@/lib/common/utils/logging/logger'

import {
  IFormConfig,
  IFormErrorListener,
  IFormErrors,
  IFormInfo,
  IFormInfoHidden,
  IFormInfoListener,
  IFormSubmitStatus,
  IFormTouched,
  IFormValidateResult,
  IFormValueListener,
  IFormValues
} from './types'
// import { getValue, setValue } from './util'

const log = logger('components.FormControl')

type IInfoKey<T> = keyof (T & IFormInfoHidden)
type IValueKey<T> = keyof T
type IInfo<T> = T & IFormInfoHidden

export class FormControl<
  FormValues extends IFormValues = IFormValues,
  FormInfo extends IFormInfo = IFormInfo
> {
  public updateId: number = 0
  public readonly config: IFormConfig<FormValues, FormInfo>
  private readonly valueListeners: Map<IValueKey<FormValues>, Set<IFormValueListener<FormValues>>>
  private readonly valuesListeners: Set<IFormValueListener<FormValues>>
  private readonly infoListeners: Map<IInfoKey<FormInfo>, Set<IFormInfoListener<FormInfo>>>
  private readonly errorListeners: Map<string, Set<IFormErrorListener>>
  private values: FormValues
  private info: FormInfo & IFormInfoHidden
  private errors: IFormErrors
  private readonly touched: IFormTouched

  constructor(config: IFormConfig<FormValues, FormInfo> = {}) {
    this.config = config
    this.values = config.values ? config.values() : ({} as FormValues)
    const info = config.info ? config.info() : ({} as FormInfo)
    this.info = {
      _status_: 'not submitted',
      _ready_: true,
      _changed_: false,
      ...info
    } as FormInfo & IFormInfoHidden
    if (this.config.validator) {
      this.config.validator(this.values, info).then(errors => {
        this.updateReady(errors)
      })
    }
    this.valueListeners = new Map()
    this.valuesListeners = new Set()
    this.infoListeners = new Map()
    this.errorListeners = new Map()
    if (config?.onChange) {
      this.valuesListeners.add(config.onChange)
    }
    this.errors = {}
    this.touched = {}
  }

  submit = async <T = void>() => {
    if (this.config.onSubmit === undefined) {
      return
    }
    await this.validate()
    if (Object.keys(this.errors).length > 0) {
      log.info('errors', this.errors)
      return
    }
    try {
      this.setStatus('submitting')
      return (await this.config.onSubmit(this)) as T
    } finally {
      this.setStatus('submitted')
    }
  }

  async validate<T extends IValueKey<FormValues>>(id?: T) {
    if (this.config.validator !== undefined) {
      const updateId = Date.now()
      this.updateId = updateId
      const errors = await this.config.validator(
        this.getValues(),
        this.getInfoValues(),
        id as string
      )
      if (updateId === this.updateId) {
        this.setErrors(errors)
        this.updateReady(this.errors)
      }
    }
  }

  reset = async () => {
    this.config.onReset?.()
    this.values = this.config.values !== undefined ? this.config.values() : ({} as FormValues)
    const info = this.config.info !== undefined ? this.config.info() : {}
    this.info = { _status_: this.info._status_, ...info } as FormInfo & IFormInfoHidden
    for (const listeners of this.valueListeners.values()) {
      for (const listener of listeners) {
        listener(this.values)
      }
    }
    for (const listeners of this.infoListeners.values()) {
      for (const listener of listeners) {
        listener(this.info)
      }
    }
    this.setErrors({})
  }

  getValues(): FormValues {
    return this.values
  }

  getInfoValues(): FormInfo & IFormInfoHidden {
    return this.info
  }

  getErrors(): IFormErrors {
    return this.errors
  }

  getValue<T extends keyof FormValues>(id: T) {
    return this.values[id] as FormValues[T]
  }

  getInfo<T extends keyof (FormInfo & IFormInfoHidden)>(id: T) {
    return this.info[id]
  }

  getError(id: string) {
    return this.errors[id]
  }

  registerValueListener(id: IValueKey<FormValues>, listener: IFormValueListener<FormValues>) {
    // const root = this.root(id)
    let listeners = this.valueListeners.get(id)
    if (listeners === undefined) {
      listeners = new Set<IFormValueListener<FormValues>>()
      this.valueListeners.set(id, listeners)
    }
    listeners.add(listener)
  }

  unregisterValueListener(id: IValueKey<FormValues>, listener: IFormValueListener<FormValues>) {
    // const root = this.root(id)
    this.valueListeners.get(id)?.delete(listener)
  }

  registerValuesListener(listener: IFormValueListener<FormValues>) {
    this.valuesListeners.add(listener)
  }

  unregisterValuesListener(handler: IFormValueListener<FormValues>) {
    this.valuesListeners.delete(handler)
  }

  registerInfoListener(id: keyof IInfo<FormInfo>, listener: IFormInfoListener<FormInfo>) {
    let listeners = this.infoListeners.get(id)
    if (listeners === undefined) {
      listeners = new Set<IFormInfoListener<FormInfo>>()
      this.infoListeners.set(id, listeners)
    }
    listeners.add(listener)
  }

  unregisterInfoListener(id: keyof IInfo<FormInfo>, listener: IFormInfoListener<FormInfo>) {
    this.infoListeners.get(id)?.delete(listener)
  }

  registerErrorListener(id: string, listener: IFormErrorListener) {
    let listeners = this.errorListeners.get(id)
    if (listeners === undefined) {
      listeners = new Set<IFormErrorListener>()
      this.errorListeners.set(id, listeners)
    }
    listeners.add(listener)
  }

  unregisterErrorListener(id: string, listener: IFormErrorListener) {
    this.errorListeners.get(id)?.delete(listener)
  }

  setStatus(status: IFormSubmitStatus) {
    this.setInfo<any, any>('_status_', status)
  }

  async setValue<T extends IValueKey<FormValues>, S extends FormValues[T]>(id: T, value: S) {
    this.values[id] = value
    // const root = this.root(id)
    const listeners = this.valueListeners.get(id)
    if (listeners !== undefined) {
      for (const listener of listeners) {
        listener(this.values)
      }
    }
    for (const listener of this.valuesListeners) {
      listener(this.values)
    }
    if (!this.info._changed_) {
      this.setInfo<any, any>('_changed_', true)
    }
    if (this.config.validationMode !== 'onSubmit') {
      await this.validate(id)
    }
  }

  async setValues(values: Partial<FormValues>) {
    this.values = { ...this.values, ...values }
    for (const listeners of this.valueListeners.values()) {
      for (const listener of listeners) {
        listener(this.values)
      }
    }
    for (const listener of this.valuesListeners) {
      listener(this.values)
    }
    if (!this.info._changed_) {
      this.setInfo<any, any>('_changed_', true)
    }
    if (this.config.validationMode !== 'onSubmit') {
      await this.validate()
    }
  }

  setInfo<T extends IInfoKey<FormInfo>, S extends IInfo<FormInfo>[T]>(id: T, info: S) {
    this.info[id] = info
    const listeners = this.infoListeners.get(id)
    if (listeners !== undefined) {
      for (const listener of listeners) {
        listener(this.info)
      }
    }
  }

  setInfoValues = (info: IFormInfo | undefined) => {
    if (info !== undefined) {
      this.info = { ...this.info, ...info }
    }
    for (const listeners of this.infoListeners.values()) {
      for (const listener of listeners) {
        listener(this.info)
      }
    }
  }

  setError(id: string, error: any) {
    if (error !== undefined) {
      this.errors[id] = error
    } else {
      delete this.errors[id]
    }
    const listeners = this.errorListeners.get(id)
    if (listeners !== undefined) {
      for (const listener of listeners) {
        listener(this.errors, this.touched)
      }
    }
  }

  clearErrors() {
    this.setErrors({})
  }

  setErrors(errors: IFormErrors) {
    const oldErrors = this.errors
    this.errors = errors
    // notify listeners for each error
    for (const [name, error] of Object.entries(errors)) {
      const oldError = oldErrors[name]
      if (oldError !== error) {
        const listeners = this.errorListeners.get(name)
        if (listeners) {
          for (const listener of listeners) {
            listener(this.errors, this.touched)
          }
        }
      }
    }

    // notify listeners for each removed error
    for (const name of Object.keys(oldErrors)) {
      if (errors[name] === undefined) {
        const listeners = this.errorListeners.get(name)
        if (listeners) {
          for (const listener of listeners) {
            listener(this.errors, this.touched)
          }
        }
      }
    }
  }

  onFocus = (ev: React.FocusEvent<HTMLElement>) => {
    ev.stopPropagation()
    const id = ev.target.id
    this.touched[id as string] = true
    const listeners = this.errorListeners.get(id)
    if (listeners !== undefined) {
      for (const listener of listeners) {
        listener(this.errors, this.touched)
      }
    }
  }

  root = (id: string) => {
    const index = id.indexOf('.')
    return index > -1 ? id.substring(0, index) : id
  }

  updateReady = (errors: IFormValidateResult) => {
    const ready = Object.keys(errors).length === 0
    if (this.info._ready_ !== ready) {
      this.setInfo<any, any>('_ready_', ready)
    }
  }
}
