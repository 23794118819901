import * as yup from 'yup'
import { ValidationError } from 'yup'

import { IFormInfo, IFormValues } from '@/components/shared/form/types'

export const yupValidator =
  (schema: yup.ObjectSchema<any>) =>
  async (values: IFormValues, context: IFormInfo, id?: string) => {
    try {
      if (id !== undefined) {
        if (schema.tests.length === 0) {
          await schema.validateAt(id, values, {
            abortEarly: false,
            context
          })
        } else {
          await schema.validate(values, {
            abortEarly: false,
            context
          })
        }
      } else {
        await schema.validate(values, {
          abortEarly: false,
          context
        })
      }
      return {}
    } catch (error: any) {
      return processErrors(error)
    }
  }

export const processErrors = (error: any): { [key: string]: string } => {
  if (Array.isArray(error.inner)) {
    return error.inner.reduce((prev: { [key: string]: string }, current: ValidationError) => {
      const { path, type, message } = current
      const name = path || type
      if (name && prev[name] === undefined) {
        prev[name] = message
      }
      return prev
    }, {})
  } else {
    return {}
  }
}
