import jp from 'jsonpath'

export const getValue = (obj: Record<string, any>, path: string) => {
  return path.indexOf('.') > -1 ? jp.value(obj, path) : obj[path]
}

export const setValue = (obj: Record<string, any>, path: string, value: any) => {
  if (path.indexOf('.') > -1) {
    jp.value(obj, path, value)
  } else {
    obj[path] = value
  }
}

export const isObject = (value: any) => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null
}

export const isArray = (value: any) => {
  return Array.isArray(value)
}
