import React from 'react'

import {
  Props as UncontrolledProps,
  TextInput as UncontrolledTextInput
} from '@/components/admin/common/form/uncontrolled/TextInput'
import { FormControl } from '@/components/shared/form/FormControl'
import { IFormInfo, IFormValues } from '@/components/shared/form/types'
import { useFormInput } from '@/components/shared/form/useFormInput'

type Props<V extends IFormValues, I extends IFormInfo> = {
  form: FormControl<V, I>
  id: keyof V
  type?: string
} & Omit<UncontrolledProps, 'value' | 'id' | 'error' | 'onFocus' | 'onChange'>

function Component<V extends IFormValues, I extends IFormInfo>({
  form,
  id,
  type,
  ...rest
}: Props<V, I>) {
  const { value, error } = useFormInput(form, id)
  return (
    <UncontrolledTextInput
      id={id as string}
      value={(value as unknown as string) ?? ''}
      error={error}
      type={type}
      onFocus={ev => form.onFocus(ev)}
      onChange={value => form.setValue(id, value as any)}
      {...rest}
    />
  )
}

export const TextInput = React.memo(Component) as unknown as typeof Component
