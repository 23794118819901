import React from 'react'

import { FormControl } from './FormControl'
import { IFormErrors, IFormInfo, IFormValues } from './types'

export const useFormError = <V extends IFormValues, I extends IFormInfo>(
  form: FormControl<V, I>,
  id: string
) => {
  const [error, setError] = React.useState(form?.getError(id))
  const onChange = React.useCallback(
    (errors: IFormErrors) => {
      setError(errors[id])
    },
    [id]
  )

  React.useEffect(() => {
    form?.registerErrorListener(id, onChange)
    return () => {
      form?.unregisterErrorListener(id, onChange)
    }
  }, [id, form, onChange])

  return error
}
