import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import showdown from 'showdown'

import { Icon } from '@/components/shared/common/Icon'
import { config } from '@/lib/common/config'
import { parsehtml } from '@/lib/common/utils/parsehtml'

interface Props {
  id: string
  tip: string
  className?: string
  placement?: 'top' | 'right' | 'bottom' | 'left'
}

export const HelpTip: React.FC<Props> = ({
  id,
  tip,
  className = 'float-right block relative m-auto',
  placement = 'right'
}) => {
  const converter = new showdown.Converter()

  return (
    <>
      <a
        data-tooltip-id={`helptip-${id}`}
        className={`${className} cursor-pointer ${
          tip ? 'text-gray-300' : 'text-red-300'
        } h-5 w-5 text-center align-middle text-sm hover:text-gray-400`}
      >
        <Icon.Help className="h-5 w-5" />
      </a>
      <ReactTooltip
        id={`helptip-${id}`}
        place={placement}
        clickable
        delayHide={0}
        className="reacttooltip rounded-3xl shadow-lg"
      >
        <div className="w-64">
          {parsehtml(converter.makeHtml(tip || config.supportMessages.missingInfo))}
        </div>
      </ReactTooltip>
    </>
  )
}
