import { IFormInfo, IFormValues } from '@/components/shared/form/types'

import { FormControl } from './FormControl'
import { useFormError } from './useFormError'
import { useFormValue } from './useFormValue'

export const useFormInput = <V extends IFormValues, I extends IFormInfo, T extends keyof V>(
  form: FormControl<V, I>,
  id: T
) => {
  const value = useFormValue(form, id)
  const error = useFormError(form, id as string)
  return {
    value,
    error
  }
}
