import React from 'react'
import { HiCheck, HiExclamationCircle } from 'react-icons/hi'
import { twMerge } from 'tailwind-merge'

import { Field, Props as FieldProps } from './Field'

export type TextInputSize = 'md' | 'lg' | 'xl'

const getIconForValidationState = (isError: boolean) =>
  isError ? (
    <HiExclamationCircle className="w-6 h-6 text-red-400" />
  ) : (
    <HiCheck className="w-6 h-6 text-green-400" />
  )

export type Props = {
  id?: string
  value?: string
  onFocus?: React.FocusEventHandler<HTMLElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: (value: string) => void
  onEnter?: () => void
  placeholder?: string
  showValidationIcon?: boolean
  icon?: React.ReactNode
  disabled?: boolean
  size?: TextInputSize
  maxLength?: number
  type?: string
  inputClassName?: string
  autoFocus?: boolean
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  leftSlot?: React.ReactNode
  rightSlot?: React.ReactNode
  errorClassName?: string
  preventLayoutShift?: boolean
  isUsedAsButton?: boolean
  autoCorrect?: 'on' | 'off'
  autoComplete?: string
} & FieldProps

export const TextInput: React.FC<Props> = ({
  id,
  value,
  error,
  onFocus,
  onBlur,
  onChange,
  onEnter,
  placeholder,
  icon,
  showValidationIcon,
  disabled = false,
  size = 'md',
  maxLength,
  type,
  inputClassName,
  autoFocus = false,
  leftIcon,
  rightIcon: rightIconFromProps,
  leftSlot,
  rightSlot,
  errorClassName,
  preventLayoutShift,
  isUsedAsButton,
  autoCorrect = 'on',
  autoComplete = 'off',
  ...fieldProps
}) => {
  const rightIcon =
    showValidationIcon === true ? getIconForValidationState(Boolean(error)) : rightIconFromProps

  return (
    <Field
      id={id}
      error={error}
      errorClassName={errorClassName}
      preventLayoutShift={preventLayoutShift}
      {...fieldProps}
    >
      <div className="flex items-center">
        {leftSlot}
        <div className="relative flex-grow">
          {leftIcon && (
            <div className="absolute transform -translate-y-1/2 left-4 top-1/2">{leftIcon}</div>
          )}
          {maxLength && <LetterCount maxLength={maxLength} value={value ?? ''} />}
          {icon && <div className="absolute right-2 top-2">{icon}</div>}
          <input
            id={id}
            className={twMerge(
              'w-full border-0 rounded-[5px] outline outline-1 outline-gray-300 bg-white font-rubik text-sm text-gray-600 placeholder:text-sm placeholder:text-gray-300 focus:outline-none focus:ring-primary focus:ring-2',
              error && 'outline-[#FF0000]',
              size == 'lg' && 'p-3',
              size == 'xl' && 'p-3 text-4xl text-[32px] leading-normal placeholder:text-[32px]',
              leftIcon && 'pl-10',
              rightIcon && 'pr-10',
              isUsedAsButton && 'cursor-pointer',
              inputClassName
            )}
            type={type || 'text'}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={ev => onChange?.(ev.target.value)}
            value={value ?? ''}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                onEnter?.()
              }
            }}
            disabled={disabled}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            autoCorrect={autoCorrect}
          />
          {rightIcon && (
            <div className="absolute transform -translate-y-1/2 right-4 top-1/2">{rightIcon}</div>
          )}
        </div>
        {rightSlot}
      </div>
    </Field>
  )
}

export const LetterCount: React.FC<{ maxLength: number; value: string; className?: string }> = ({
  maxLength,
  value,
  className
}) => {
  const count = value.length
  const remaining = maxLength - count

  return (
    <div
      className={twMerge(
        'absolute bottom-0.5 right-2 text-xs text-gray-500',
        remaining < 0 && 'text-red-600',
        className
      )}
    >
      {remaining.toLocaleString()} {remaining >= 0 && 'remaining'}
    </div>
  )
}
