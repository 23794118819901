import parse from 'html-react-parser'

export function parsehtml(rawhtml: string, trusted = false) {
  // const noscript = <div>noscript</div>

  const parseoptions = {
    replace: (domNode: any) => {
      const { attribs } = domNode
      //if (domNode instanceof Element && attribs) {
      if (!attribs) {
        return
      }

      //remove any scripts that are here...
      if (domNode.name === 'script' && !trusted) {
        return <></>
      }

      //remove any scripts that are here...
      if (domNode.name === 'iframe' && !trusted) {
        return <></>
      }

      //}
    }
  }
  return parse(rawhtml, parseoptions)
}
