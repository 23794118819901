import React from 'react'

import { IFormInfo, IFormValues } from '@/components/shared/form/types'

import { FormControl } from './FormControl'

export const useFormSubmitStatus = <V extends IFormValues, I extends IFormInfo>(
  form: FormControl<V, I> | undefined
) => {
  const [info, setInfo] = React.useState(form ? form.getInfo('_status_') : 'not submitted')
  const onChange = React.useCallback(info => setInfo(info['_status_']), [])

  React.useEffect(() => {
    form?.registerInfoListener('_status_', onChange)
    return () => {
      form?.unregisterInfoListener('_status_', onChange)
    }
  }, [form, onChange])

  return info
}
