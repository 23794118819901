import React from 'react'

import { IFormInfo, IFormValues } from '@/components/shared/form/types'
import { getValue, isArray, isObject } from '@/components/shared/form/util'

import { FormControl } from './FormControl'

export const useFormValue = <V extends IFormValues, I extends IFormInfo, T extends keyof V>(
  form: FormControl<V, I>,
  id: T
) => {
  const [value, setValue] = React.useState(form.getValue(id as T))
  const onChange = React.useCallback(
    values => {
      const tmp = getValue(values, id as string)
      if (isObject(tmp)) {
        setValue({ ...tmp })
      } else if (isArray(tmp)) {
        setValue([...tmp] as any)
      } else {
        setValue(tmp)
      }
    },
    [id]
  )

  React.useEffect(() => {
    form?.registerValueListener(id, onChange)
    return () => {
      form?.unregisterValueListener(id, onChange)
    }
  }, [id, form, onChange])

  return value
}
