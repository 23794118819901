import { nanoid } from 'nanoid'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { IInputStyle } from '@/components/admin/common/form/controlled/types'
import { Error } from '@/components/admin/common/form/uncontrolled/Error'
import { HelpTip } from '@/components/admin/common/HelpTip'

export interface Props {
  id?: string
  label?: React.ReactElement | string
  help?: string
  error?: string
  premium?: boolean
  className?: string
  inputStyle?: IInputStyle
  errorClassName?: string
  preventLayoutShift?: boolean
}

export const Field: React.FC<Props> = ({
  id,
  className,
  label,
  help,
  error,
  premium,
  inputStyle = 'default',
  errorClassName,
  preventLayoutShift,
  children
}) => {
  const fieldId = id ?? nanoid()
  return (
    <div
      id={'_field_' + fieldId}
      className={twMerge(
        inputStyle !== 'narrow' && 'w-full',
        className,
        inputStyle === 'blue' && 'rounded bg-blue-100 px-4 pb-8 pt-4'
      )}
    >
      {help && <HelpTip id={fieldId} tip={help} />}
      {label && (
        <label htmlFor={fieldId}>
          <h3 className="mb-2 text-[16px] font-bold text-gray-600">
            {premium && <span className="mr-2 text-primary">★</span>}
            {label}
          </h3>
        </label>
      )}
      {children}
      <Error
        error={error}
        errorClassName={errorClassName}
        preventLayoutShift={preventLayoutShift}
      />
    </div>
  )
}
