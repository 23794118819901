import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon } from '@/components/shared/common/Icon'

interface Props {
  error?: string
  errorClassName?: string
  preventLayoutShift?: boolean
}

export const Error: React.FC<Props> = ({ error, errorClassName, preventLayoutShift }) => {
  if (!error && !preventLayoutShift) {
    return null
  }
  return (
    <div
      className={twMerge(
        'mr-2 mt-1 w-full text-sm text-red-600 inline-flex items-center',
        errorClassName
      )}
    >
      {error !== undefined && (
        <>
          <Icon.CloseError className="w-6 h-6 text-red-600" />
          <span>{error}</span>
        </>
      )}
    </div>
  )
}
